export const messages = {
  'message': {
    'header': {
      'title': 'Калькулятор звукоизоляции',
      'error': 'Нашли {break}ошибку?',
      'coder': 'Код {break}на сайт'
    },
    'nav': {
      'step1': 'Исходные данные',
      'step2': 'Строительная система',
      'step3': 'Конструкция',
      'step4': 'Результат'
    },
    'home': {
      'logo': '<svg xmlns="http://www.w3.org/2000/svg" width="144" height="24" fill="none"><path fill="#E30713" d="m24.324 19.2-4.865 4.799L1.465 24 0 22.554V4.8L4.865 0h17.994l1.465 1.445V19.2Z"/><path fill="#fff" d="m22.86 0-5.472 5.398v7.879L12.907 7.2H3.46V9.11h2.785v7.283L0 22.553V24l1.465-.001 6.877-6.786V9.11h2.784v7.69h2.071v-6.077l4.482 6.076h1.78V6.245l4.864-4.8V0H22.86Z"/><path fill="#000" d="M32.107 9.532h-2.919V7.2h8.534v2.332h-2.918v7.267h-2.697V9.532ZM39.14 7.2h7.824v2.262h-5.156v1.455h4.67v2.099h-4.67v1.521h5.226V16.8H39.14V7.201ZM51.474 11.918 48.276 7.2h3.1L53.1 9.9l1.738-2.7h3.029l-3.196 4.69 3.336 4.908h-3.1l-1.864-2.893-1.875 2.893h-3.03l3.336-4.881ZM59.282 7.2h2.696v3.593h3.461V7.201h2.697v9.598h-2.697v-3.648h-3.46V16.8h-2.697V7.201ZM69.755 12c0-2.757 2.252-4.992 5.255-4.992 3 0 5.225 2.208 5.225 4.992 0 2.757-2.252 4.992-5.253 4.992-3.004 0-5.227-2.209-5.227-4.992Zm7.728 0c0-1.385-1.015-2.593-2.502-2.593-1.474 0-2.46 1.18-2.46 2.593 0 1.386 1.015 2.59 2.488 2.59 1.486 0 2.474-1.177 2.474-2.59ZM81.867 7.2h2.697v3.593h3.46V7.201h2.698v9.598h-2.698v-3.648h-3.46V16.8h-2.697V7.201ZM92.82 7.2h2.669v5.403L99.256 7.2h2.515v9.598h-2.669v-5.402l-3.767 5.402h-2.516V7.201ZM103.87 7.2h2.695v3.896l3.35-3.895h3.197l-3.683 4.127 3.808 5.471h-3.238l-2.446-3.564-.988 1.082V16.8h-2.695V7.2ZM113.258 12c0-2.757 2.25-4.992 5.252-4.992 3.003 0 5.227 2.208 5.227 4.992 0 2.757-2.252 4.992-5.254 4.992s-5.225-2.209-5.225-4.992Zm7.726 0c0-1.385-1.013-2.593-2.501-2.593-1.474 0-2.46 1.18-2.46 2.593 0 1.386 1.014 2.59 2.487 2.59 1.488 0 2.474-1.177 2.474-2.59ZM135.785 7.2h2.696v3.016h1.473c2.336 0 4.046 1.099 4.046 3.264 0 2.072-1.529 3.32-3.906 3.32h-4.309V7.2Zm4.1 7.365c.889 0 1.418-.398 1.418-1.152 0-.672-.529-1.111-1.404-1.111h-1.418v2.263h1.404ZM126.124 7.2v4.212c0 .995-.102 2.434-.767 2.943-.313.24-.829.422-1.155.422v2.263h.255c.933 0 1.977-.37 2.379-.621 1.626-1.019 1.827-2.957 1.827-5.145V9.532h2.327v7.267h2.697V7.201h-7.563Z"/></svg>',
      'tabTable': 'ТЕХНОНИКОЛЬ | Калькулятор звуковой изоляции'
    },
    'wizard': {
      'titles': {
        'constructionTypes': 'Исходные данные',
        'systemFilter': {
          'title': 'Выберите строительную систему',
          'subtitle': 'Выберите систему, которая будет использоваться на данном участке. Это повлияет на параметры основания конструкции'
        },
        'constructionParams': 'Конструкция'
      }
    },
    'buttons': {
      'next': 'Продолжить',
      'back': 'Назад',
      'cancel': 'Отменить',
      'delete': 'Удалить',
      'showMore': 'Показать ещё',
      'minimize': 'Свернуть',
      'showDetails': 'Показать подробности',
      'hideDetails': 'Свернуть подробности',
      'addCalculation': 'Добавить участок',
      'blocked': 'Заблокировано',
      'download': 'Скачать',
      'save': 'Сохранить расчёт в ЛК',
      'quick': 'Быстрый просмотр',
      'select': 'Выбрать систему'
    },
    'modal': {
      'removing': {
        'title': 'Удаление участка',
        'text': 'Вы уверены, что хотите удалить участок?\n Отменить это действие будет невозможно.'
      },
      'details': {
        'materials-title': 'Состав',
        'indexes': {
          'rw': 'Индекс изоляции воздушного шума',
          'lnw': 'Индекс изоляции ударного шума'
        }
      }
    },
    'constructionTypes': {
      'input': {
        'label': 'Наименование участка'
      },
      'types': {
        'title': 'Тип конструкции',
        'walls': 'Стены и перегородки',
        'floor': 'Перекрытия'
      },
      'buildings': {
        'title': 'Тип здания',
        'types': {
          'flat': 'Жилые здания',
          'hotel': 'Гостиницы',
          'office': 'Административные здания, офисы',
          'hospital': 'Больницы и санатории',
          'school': 'Образовательные организации',
          'preschool': 'Дошкольные образовательные организации'
        }
      },
      'location': {
        'title': 'Расположение конструкции'
      },
      'rooms': {
        'top': 'Верхнее помещение',
        'bottom': 'Нижнее помещение'
      },
      'rating': {
        'title': 'Рейтинг гостиницы',
        'categories': {
          'five': 'Пять звёдз',
          'four': 'Четыре зведзы',
          'three': 'Три зведзы'
        }
      },
      'message': {
        'markedOption': 'Технические помещения',
        'addFloorParams': 'Расчет для помещений с источниками ударного шума и вибраций, оборудованием, а также для ресторанов, коммерческих помещений и др., над которыми располагаются жилые или рабочие помещения',
        'technicalRooms': '* Требования к индексу ударного шума перекрытий технических помещений согласно СП 51.13330.2011 не предъявляются. Однако, учитывая физическую составляющую распространения ударного (структурного) шума по конструкциям, рекомендуется ориентироваться на требования для помещений с аналогичными источниками шума, указанные в табл.3 СП 51.13330.2011. Кроме того, рекомендуется предусмотреть дополнительные мероприятия по виброизоляции оборудования.',
        'systemWarning': 'Обеспечить повышенные требования норм по ударному шуму возможно только с применением системы ТН-ПОЛ Акустик Проф'
      }
    },
    'constructionParams': {
      'params': {
        'title': 'Укажите параметры конструкции',
        'base': 'Основание конструкции',
        'screed': 'Стяжка',
        'density': 'Плотность, кг/м³',
        'thickness': 'Толщина, h'
      },
      'common': {
        'density': 'Плотность, кг/м³',
        'thickness': 'Толщина, h',
        'sheets': 'Количество листов',
        'soundIsolation': 'Звукоизоляционный материал',
        'error-message': 'Введите число от {min} до {max}, иначе расчет будет выполнен с минимальным значением параметра.',
        'tooltip-message': 'Значения параметра для выбранного материала варьируются в пределах {limit}'
      },
      'constructions': {
        'title': 'Выберите конструкцию'
      },
      'indexes': {
        'title': 'Нормативные требования',
        'rw': 'Требуемый индекс изоляции воздушного шума, R',
        'lnw': 'Требуемый индекс изоляции ударного шума, L',
        'subs': {
          'rw': 'w треб',
          'rw-tested': 'w исп',
          'lnw': 'nw треб'
        },
        'calculated': {
          'rw': 'Расчетный индекс изоляции воздушного шума, R',
          'rw-tested': 'Индекс изоляции воздушного шума согласно протоколам испытаний, R',
          'lnw': 'Расчетный индекс изоляции ударного шума, L',
          'subs': {
            'rw': 'w расч',
            'rw-tested': 'w исп',
            'lnw': 'nw расч'
          }
        }
      },
      'facingParams': {
        'constructionGroup': 'Облицовка',
        'title': 'Укажите параметры облицовки',
        'facing': 'Материал облицовки',
        'density': 'Плотность облицовки',
        'thickness': 'Толщина облицовки',
        'sheets': 'Количество листов облицовки'
      },
      'isolationParams': {
        'constructionGroup': 'Изоляция',
        'title': 'Укажите параметры каркаса',
        'isolation': 'Материал изоляции',
        'thickness': 'Толщина изоляции'
      },
      'soundIsolationParams': {
        'constructionGroup': 'Звукоизоляционный материал',
        'soundIsolation': 'Звукоизоляционный материал',
        'title': 'Укажите звукоизоляционный материал',
        'thickness': 'Толщина звукоизоляционного материала'
      }
    },
    'filling': {
      'rw': 'Данная система не удовлетворяет требованиям СП 51.13330.2011 по изоляции воздушного шума, рекомендуем изменить параметры системы или выбрать систему ТН-ПОЛ Проф Акустик, ТН-ПОТОЛОК Акустик или добавить подшивку снизу плиты перекрытия (систему звукоизоляции ТН-ПОТОЛОК Акустик).',
      'lnw': 'Данная система не удовлетворяет требованиям СП 51.13330.2011 по изоляции ударного шума, рекомендуем изменить параметры системы или увеличить толщину или плотность основания конструкции.',
      'add-filling-button': 'Добавить подшивку снизу плиты перекрытия',
      'hasFilling': 'Добавлена подшивка снизу плиты перекрытия'
    },
    'footer': {
      'links': [
        {
          'url': 'https://shop.tn.ru/teploizoljacija',
          'title': 'Где купить?',
          'statisticLabel': 'calc-sound-wheretobuy'
        },
        {
          'url': 'https://nav.tn.ru/services/proektno-raschetnyy-tsentr/form/',
          'title': 'Заказать профессиональный расчет в ПРЦ',
          'statisticLabel': 'calc-sound-prc'
        },
        {
          'url': 'https://nav.tn.ru/services/support/',
          'title': 'Задать вопрос эксперту',
          'statisticLabel': 'calc-sound-expert'
        }
      ]
    },
    'result': {
      'initialData': {
        'title': 'Исходные данные',
        'calculationType': 'Тип расчета',
        'systemTitle': 'Строительная система'
      },
      'systemDetails': {
        'title': 'Состав системы',
        'totalThickness': 'Толщина конструкции'
      },
      'indexesCalculationAll': 'Результаты расчета по воздушному и ударному шуму',
      'indexesCalculationRw': 'Результаты расчета по воздушному шуму',
      'recap': {
        'floors': {
          'satisfied': 'Данная система удовлетворяет требованиям СП51.13330.2011 по изоляции воздушного и ударного шума',
          'noSatisfied': 'Данная система не удовлетворяет требованиям СП51.13330.2011 по изоляции воздушного и ударного шума'
        },
        'floorsWithoutRw': {
          'satisfied': 'Данная система удовлетворяет требованиям СП51.13330.2011 по изоляции ударного шума',
          'noSatisfied': 'Данная система не удовлетворяет требованиям СП51.13330.2011 по изоляции ударного шума'
        },
        'walls': {
          'satisfied': 'Данная система удовлетворяет требованиям СП51.13330.2011 по изоляции воздушного шума',
          'noSatisfied': 'Данная система не удовлетворяет требованиям СП51.13330.2011 по изоляции воздушного шума'
        }
      }
    },
    'export': {
      'title': 'Сохраните или скачайте результат',
      'detailsTitle': 'Подробный расчет',
      'auth': 'Войдите в личный кабинет'
    },
    'advantages': {
      'title': 'Что может калькулятор?',
      'description': 'Конструктор поможет подобрать систему звукоизоляции ТЕХНОНИКОЛЬ и проверить на соответствие требованиям стандартов по изоляции воздушного и ударного шума.',
      'calculation-examples': {
        'title': 'Примеры расчетов',
        'floor': 'Перекрытия, отделяющие номера от помещений общего пользования (вестибюли, холлы, буфеты)',
        'hotel-walls': 'Стены и перегородки между номерами гостиницы «три звезды»',
        'home-walls': 'Перегородки между комнатой и санузлом одной квартиры'
      }
    },
    'aside': {
      'title': 'Остались вопросы?',
      'linkTitle': 'Задавайте'
    },
    'links': {
      'logo': 'https://nav.tn.ru/',
      'policy': 'https://nav.tn.ru/privacy/#policy_conf',
      'agreement': 'https://nav.tn.ru/privacy/',
      'build-in': 'https://nav.tn.ru/calculators/embed/',
      'error': 'https://bit.ly/2O0QIGH'
    },
    'icons': {
      'pencil': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><g stroke="#676B77" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" clip-path="url(#a)"><path d="M4 20h4L18.5 9.5a2.829 2.829 0 0 0-4-4L4 16v4ZM13.5 6.5l4 4"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>',
      'caret': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#1F1F1F" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m6 9 6 6 6-6"/></svg>',
      'ok': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><g clip-path="url(#a)"><path stroke="#16C861" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="m5 12 5 5L20 7"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>',
      'delete': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><g stroke="#E72430" stroke-linecap="round" stroke-linejoin="round" clip-path="url(#a)"><path stroke-width="2" d="M4 7h16"/><path stroke-width="1.6" d="M10 11v6M14 11v6M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>',
      'close': '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="#676B77" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 1 1 17M1 1l16 16"/></svg>',
      'attention': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10ZM12 8v4M12 16h.01"/></svg>',
      'plus': '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none"><g stroke="#E72430" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" clip-path="url(#a)"><path d="M9 4.25v10.5M3.75 9.5h10.5"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 .5h18v18H0z"/></clipPath></defs></svg>',
      'wide': '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.668 16.667H3.335V8.334M9.168 2.5h8.333v8.334"/></svg>',
      'file': '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="44" fill="none"><path stroke="#E72430" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M20.295.879h-14.8a4.229 4.229 0 0 0-4.23 4.229v33.83a4.229 4.229 0 0 0 4.23 4.228h25.372a4.229 4.229 0 0 0 4.229-4.228V15.68L20.296.879Z"/><path stroke="#E72430" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M20.297.879v14.8h14.8"/></svg>',
      'eye': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8Z"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/></svg>'
    },
    'units': {
      'dB': 'дБ',
      'pc': 'шт.',
      'pa': 'кг/м³',
      'mm': 'мм'
    },
    'store': {
      'sector': 'Участок',
      'defaultType': 'Стены и перегородки',
      'calculation': 'Расчет',
      'floor': 'перекрытия',
      'walls': 'стен и перегородок',
      'exportTitle': 'Расчёт звукоизоляции'
    },
    'algorithms': {
      'pro': 'Расчет PRO',
      'lite': 'Расчет Lite'
    }
  }
}
