<template>
  <article class="recap">
    <p class="recap__text">{{ recap }}</p>
    <p class="recap__formula">
      <span v-if="hasRw" class="recap__air-indexes">
        R<sub>{{ $t(`message.constructionParams.indexes.calculated.subs.${isRwTested ? 'rw-tested' : 'rw'}`) }}</sub>
        <span>{{ isRwSatisfied ? ' ≥ ' : ' ≤ '}}</span>
        R<sub>{{ $t('message.constructionParams.indexes.subs.rw') }}</sub>
      </span>
      <span v-if="isFloor" class="recap__shock-indexes">
        L<sub>{{ $t('message.constructionParams.indexes.subs.lnw') }}</sub>
        <span>{{ isLnwSatisfied ? ' ≥ ' : ' ≤ '}}</span>
        L<sub>{{ $t('message.constructionParams.indexes.calculated.subs.lnw') }}</sub>
      </span>
    </p>
  </article>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'

export default {
  name: 'Recap',
  props: ['indexes'],
  mixins: [IconMixin],
  computed: {
    isRwTested() {
      return this.indexes.isTested
    },
    isFloor() {
      return this.indexes.required.lnw !== null
    },
    isRwSatisfied() {
      return this.indexes.calculated.rw >= this.indexes.required.rw
    },
    hasRw() {
      return this.indexes.calculated.rw !== null && this.indexes.required.rw !== null
    },
    isLnwSatisfied() {
      return this.indexes.required.lnw >= this.indexes.calculated.lnw
    },
    recap() {
      const isSatisfied = this.isFloor ? (this.isRwSatisfied && this.isLnwSatisfied) : this.isRwSatisfied
      let constructionType = this.isFloor ? 'floors' : 'walls'

      if (this.isFloor && !this.hasRw) {
        constructionType = constructionType + 'WithoutRw'
      }

      return isSatisfied
        ? this.$t(`message.result.recap.${constructionType}.satisfied`)
        : this.$t(`message.result.recap.${constructionType}.noSatisfied`)
    }
  }
}
</script>

<style scoped lang="sass">
.recap
  display: grid
  grid-template-columns: auto auto
  +media((grid-template-areas: (0: 'text text' 'formula formula', 620: 'text formula' 'text formula')))
  padding: rem(20)
  margin: 0 0 rem(30)
  border-radius: $block-radius
  background: $success
  text-align: left
  &__text
    grid-area: text
    margin: rem(7) auto rem(7) 0
    max-width: rem(440)
    +media((margin: (0: rem(7) 0 rem(7) 0, 620: rem(7) rem(10) rem(7) 0)))
  &__formula
    grid-area: formula
    display: flex
    flex-shrink: 0
    align-self: center
    gap: rem(8)
    @extend %18
    line-height: 1.3
    +media((flex-direction: (0: row, 620: column)))
    +media((justify-content: (0: space-around, 620: flex-start)))
    +media((margin: (0: rem(10) 0 0 0, 620: 0 0 0 auto)))
</style>
