<template>
  <article class="dropdown" :style="{ 'grid-area': `${dropdown.id}` }" @click="focusInput">
    <span
      class="p-float-label"
      :class="{ measure: measure, 'only-custom': onlyCustom }"
    >
    <Dropdown
      ref="input"
      :id="`${dropdown.id}`"
      :options="dropdown.source"
      optionLabel="value"
      dataKey="value"
      scrollHeight="190px"
      :disabled="dropdown.disabled"
      :editable="dropdown.editable"
      :class="{ 'p-invalid': invalid }"
      :value="selected"
      @change="handle($event)"
      @input="validInputValue($event)"
    >
      <template #indicator>
        <span
          class="p-dropdown-icon"
          v-if="!(measure && dropdown.disabled) && !onlyCustom"
          v-html="getIcon('caret')"
        ></span>
        <span class="p-dropdown-measure" v-if="measure">{{ measure }}</span>
      </template>
    </Dropdown>
    <label :for="`${dropdown.id}`">{{ dropdown.label }}</label>
  </span>
    <span class="message__container">
      <span v-if="showTooltip" class="tooltip-message">{{ tooltipMessage }}</span>
      <span v-if="invalid" class="error-message">{{ errorMessage }}</span>
    </span>
  </article>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'
import Dropdown from 'primevue/dropdown'

export default {
  name: 'OptionDropdown',
  props: ['dropdown', 'validationRules', 'type', 'updateKey'],
  mixins: [IconMixin],
  components: { Dropdown },
  watch: {
    updateKey() {
      this.selected = this.getSelectedOption() ?? null
      this.invalid = typeof this.selected === 'number' && this.dropdown.editable ? !this.checkValidity(this.selected) : false
    },
    dropdown() {
      this.selected = this.getSelectedOption() ?? null
      if (this.selected?.value === this.dropdown?.validationRules?.min || this.selected === this.dropdown?.validationRules?.min) {
        this.invalid = false
      }
    }
  },
  data: () => ({
    selected: null,
    invalid: false,
    hasFocus: false,
    pattern: /[^\d.,]/g
  }),
  computed: {
    isFloat() {
      return this.type === 'float'
    },
    measure() {
      return this.dropdown?.source?.find(option => option.unit)?.unit
    },
    onlyCustom() {
      return this.dropdown.source?.length === 1 && this.dropdown.source[0].value === ''
    },
    errorMessage() {
      return this.$t('message.constructionParams.common.error-message', { min: this.validationRules.min, max: this.validationRules.max })
    },
    tooltipMessage() {
      return this.$t('message.constructionParams.common.tooltip-message', { limit: this.dropdown.tooltip })
    },
    showTooltip() {
      return this.invalid ? false : (this.dropdown.tooltip && this.hasFocus)
    }
  },
  mounted() {
    if (this.dropdown.selected) {
      this.selected = this.getSelectedOption()
    }
    const input = this.$refs.input.$el
    if (input) {
      const mo = new MutationObserver((mutationsList, observer) => {
        if (!mutationsList[0].target.classList.contains('p-inputwrapper-focus')) {
          this.hasFocus = false
        }
      })
      mo.observe(input, { attributes: true })
    }
  },
  methods: {
    getSelectedOption() {
      return this.dropdown.source.find((src) => src.value === this.dropdown.selected || src.value === this.dropdown.selected?.title) ||
        this.dropdown.selected
    },
    focusInput() {
      if (this.dropdown.editable && this.$refs.input.$el) {
        this.$refs.input.$el.querySelector('input.p-inputtext').focus()
        this.hasFocus = true
      }
    },
    handle(evt) {
      let newValue = evt.value.value

      switch (this.dropdown.id) {
        case 'location':
        case 'screed':
        case 'base':
        case 'facing':
        case 'isolation':
        case 'soundIsolation':
          newValue = { title: evt.value.value, id: evt.value.id }
          break
        case 'thickness':
          if (evt.value.count) {
            newValue = {
              title: evt.value.value,
              count: evt.value.count,
              value: parseFloat((evt.value.value).split('x')?.[1] || evt.value.value)
            }
          }
          break
        default:
          break
      }

      this.$emit('update', { newValue, valid: true })
      this.invalid = false
    },
    defineValidityDependOnType(value) {
      this.selected = this.isFloat ? value || null : parseInt(value) || null

      return this.validationRules === null || this.selected === null
        ? true
        : this.checkValidity(this.isFloat ? parseFloat(this.selected) : this.selected)
    },
    handleEmptyValue() {
      this.invalid = true
      this.$emit('update', { newValue: null, valid: false })
      this.selected = ''
    },
    validInputValue(evt) {
      if (typeof evt === 'object') {
        this.selected = evt
        return
      }

      const rawValue = evt.trim().replaceAll(this.pattern, '').replaceAll(',', '.')
      if (!rawValue) {
        this.handleEmptyValue()
        return
      }

      const isValid = this.defineValidityDependOnType(rawValue)
      const emittedValue = this.isFloat ? parseFloat(this.selected) : this.selected

      this.$emit('update', { newValue: emittedValue, valid: isValid })
      this.invalid = !isValid
    },
    checkValidity(val) {
      return val >= this.validationRules.min && val <= this.validationRules.max
    }
  }
}
</script>

<style lang="sass">
%customHighlight
  background: $grey-fill
  color: $main-text

.p-float-label
  label
    display: flex
    padding: 0 rem(15)
    text-align: left
    color: $placeholder
  .p-inputwrapper-filled, .p-inputwrapper-focus
    & + label
      padding: 0 rem(4)
      top: 0
      left: 0
      transform: translate(rem(11), 20%)
      color: $text-gray
      background: $white
    input
      outline: none
  &.measure
    .p-dropdown
      padding: rem(18) rem(120) rem(18) rem(15)
  & .p-disabled + label
    border-top-right-radius: $input-radius
    border-bottom-right-radius: $input-radius
  &.only-custom
    .p-dropdown
      cursor: default
    .p-dropdown-panel
      display: none
    .p-inputwrapper-focus
      border-radius: $block-radius

.p-dropdown
  display: flex
  width: 100%
  padding: rem(18) rem(54) rem(18) rem(15)
  position: relative
  border: 1px solid $border-color
  border-radius: $block-radius
  @extend %16
  color: $main-text
  cursor: pointer
  +media((padding: (0: rem(15) rem(51) rem(15) rem(15), 768: rem(18) rem(54) rem(18) rem(15))))
  &.p-invalid
    border-color: $info!important
  &-label
    padding-top: 0
    padding-bottom: 0
    text-align: left
    @extend %16
    border: none
  .p-hidden-accessible
    display: none
  &-panel
    display: flex
    width: 100%
    padding-left: rem(13)
    padding-right: rem(47)
    +media((padding-top: (0: rem(18), 768: rem(15))))
    +media((padding-bottom: (0: rem(18), 768: rem(15))))
    border-radius: $block-radius
    background: $white
  &-panel
    padding: 0
    border-color: $border-color
  &-items-wrapper, &-items
    display: flex
    width: 100%
  &-items
    flex-flow: column nowrap
    height: fit-content
  &-item
    width: 100%
    @extend %16
    padding: rem(18) rem(20)
    text-align: left
    text-wrap: unset
    &:hover
      background: $bg-grey
  &-trigger, &-icon
    height: 100%
  &-trigger
    position: absolute
    top: 50%
    right: 0
    transform: translate(0, -50%)
  &-icon
    position: relative
    display: flex
    align-items: center
    justify-content: center
    margin-right: rem(13)
  &-measure
    display: flex
    align-items: center
    height: calc(100% - 1px)
    padding: 0 rem(15)
    margin-left: rem(-13)
    color: $checked-border-color
    border-top-right-radius: $block-radius
    border-bottom-right-radius: $block-radius
  &.p-inputwrapper-focus
    border-color: #BABECA
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
    .p-dropdown-panel
      width: calc(100% + 2px)
      padding: 0 0 rem(15)
      border-top-left-radius: 0
      border-top-right-radius: 0
      transform: translate(rem(-1), rem(-1))
      box-shadow: 0 20px 85px 0 rgba(141, 148, 177, 0.15)
    .p-dropdown-icon
      transform: rotate(180deg)
  &.p-inputwrapper-focus.p-focus
    border-bottom-left-radius: $block-radius
    border-bottom-right-radius: $block-radius
    .p-dropdown-icon
      transform: rotate(0deg)
.message__container
  width: 100%
  position: absolute
  top: 0
  left: 0
  text-align: left
  transform: translate(0, 4.3rem)
  +media((transform: (0: translate(0, 3.5rem), 768: translate(0, 4.3rem))))
  color: $red
  @extend %12
.tooltip-message
  color: $success
.dropdown
  position: relative
  &[style*='rating']
    +media((width: (0: 100%, 992: 220px)))
    +media((margin: (0: rem(20) 0 0 0, 992: 0 0 0 rem(20))))
  &[style*='location']
    +media((margin-top: (0: rem(20), 992: rem(30))))
  &:has(.error-message),
  &:has(.tooltip-message)
    +media((padding-bottom: (0: 2.5rem, 800: 3rem, 1230: 4rem)))
    padding-bottom: 3.5rem
</style>
