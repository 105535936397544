<script>
import { CalcTypes, ConstructionTypes } from '@/utils/types'

export default {
  name: 'DropdownConfigsMixin',
  methods: {
    getOptions(data, needOrder = false) {
      const options = data
        .map((item, index) => ({
          id: item.systemId ?? item.id ?? index,
          value: item.name ?? item.title ?? item,
          ...item
        }))

      return needOrder ? options.slice().sort((a, b) => a.order - b.order) : options
    },
    getValidationRule(param) {
      const key = `${param}Rule`

      if (this.material && this.material[key]) {
        const { min, max, unitType } = this.material[key]
        this.material.toolTip = `${min}-${max} ${unitType}`

        return { min, max }
      }

      return this.validationRules[this.currentConstructionType][this.isProAlgorithm ? CalcTypes.pro : CalcTypes.lite][param]
    }
  },
  computed: {
    dropdowns() {
      return [
        {
          id: 'buildingType',
          label: this.$t('message.constructionTypes.buildings.title'),
          source: this.buildingTypes,
          viewCondition: true,
          selected: this.buildingTypes?.find((item) => item.value === this.buildingType)?.value,
          disabled: false
        },
        {
          id: 'rating',
          label: this.$t('message.constructionTypes.rating.title'),
          source: this.ratings,
          viewCondition: this.isHotel,
          selected: this.sector.constructionType.rating,
          disabled: !this.buildingType
        },
        {
          id: 'location',
          label: this.$t('message.constructionTypes.location.title'),
          source: this.locations,
          viewCondition: true,
          selected: this.locations?.find((item) => item.id === this.location?.id)?.value ?? null,
          disabled: !this.buildingType
        }
      ]
    },
    validationRules() {
      return {
        [ConstructionTypes.floor]: {
          [CalcTypes.pro]: {
            density: { min: 500, max: 2800 },
            thickness: { min: 140, max: 1000 }
          },
          [CalcTypes.lite]: {
            density: { min: 1, max: 2800 },
            thickness: { min: 140, max: 1000 }
          }
        },
        [ConstructionTypes.wall]: {
          [CalcTypes.pro]: {
            density: { min: 500, max: 1500 },
            thickness: { min: 5, max: 50 }
          },
          [CalcTypes.lite]: {
            density: { min: 1, max: 500 },
            thickness: { min: 5, max: 50 }
          }
        }
      }
    },
    additionalFloorParams() {
      return [
        {
          id: 'buildingType',
          label: this.$t('message.constructionTypes.rooms.top'),
          source: this.topRooms,
          viewCondition: true,
          selected: this.topRooms?.find((item) => item.value === this.buildingType)?.value ?? null,
          disabled: false
        },
        {
          id: 'location',
          label: this.$t('message.constructionTypes.rooms.bottom'),
          source: this.bottomRooms,
          viewCondition: true,
          selected: this.bottomRooms?.find((item) => item.id === this.location?.id)?.value ?? null,
          disabled: !this.buildingType
        }
      ]
    },
    hasTooltipForThickness() {
      const withoutDensity = this.material?.defaultValues && this.material?.defaultValues?.density === null

      return withoutDensity && Boolean(this.material?.toolTip)
    },
    structure() {
      return [
        {
          id: this.type,
          label: this.getLabel(),
          source: this.formattedMaterials,
          viewCondition: true,
          selected: this.title,
          disabled: false,
          validationRules: null,
          type: 'text',
          tooltip: null
        },
        {
          id: 'density',
          label: this.$t('message.constructionParams.common.density'),
          source: this.densities,
          viewCondition: this.isFacing || this.isBase,
          selected: this.selected?.density ?? null,
          editable: this.material?.isCustomDensity,
          disabled: !this.title,
          validationRules: this.getValidationRule('density'),
          type: 'float',
          tooltip: this.material?.densityRule ? this.material?.toolTip : null
        },
        {
          id: 'thickness',
          label: this.$t('message.constructionParams.common.thickness'),
          source: this.thicknesses,
          viewCondition: true,
          selected: this.selected?.thickness ?? null,
          editable: this.material?.isCustomThickness,
          disabled: !this.title,
          validationRules: this.getValidationRule('thickness'),
          type: 'float',
          tooltip: this.material?.thicknessRule ? this.material?.toolTip : null
        },
        {
          id: 'sheets',
          label: this.$t('message.constructionParams.common.sheets'),
          source: this.sheets,
          viewCondition: this.isFacing,
          selected: this.selected?.sheets ?? null,
          disabled: !this.title,
          validationRules: null,
          type: 'text',
          tooltip: null
        }
      ]
    }
  }
}
</script>
