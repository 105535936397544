<template>
  <div class="structure" :class="{ 'structure--pro': isProAlgorithm }">
    <slot></slot>
    <div
      class="structure__wrapper"
      :class="{
      'structure__wrapper--facing': isFacing,
      'structure__wrapper--base': isBase,
      'structure__wrapper--sound': isSoundIsolation,
      'structure__wrapper--screed': isScreed
    }"
    >
      <template v-for="param of structure">
        <OptionDropdown
          v-if="param.viewCondition"
          :updateKey="`${sector.id}_${param.id}`"
          :dropdown="param"
          :validationRules="param.validationRules"
          :type="param.type"
          :key="param.id"
          @update="update($event, type, `${param.id}`)"
        ></OptionDropdown>
      </template>
    </div>
  </div>
</template>

<script>

import OptionDropdown from '@/components/dump/OptionDropdown'
import { CalcTypes, ConstructionTypes } from '@/utils/types'
import DropdownConfigsMixin from '@/components/mixin/DropdownConfigsMixin.vue'
import { getSectorConstructionTypeId } from '@/utils/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'Structure',
  components: { OptionDropdown },
  props: ['params', 'type', 'sector'],
  mixins: [DropdownConfigsMixin],
  computed: {
    ...mapGetters({
      getConstructionTypes: 'getConstructionTypes'
    }),
    isFacing() {
      return this.type === 'facing'
    },
    isBase() {
      return this.type === 'base'
    },
    isSoundIsolation() {
      return this.type === 'soundIsolation'
    },
    isScreed() {
      return this.type === 'screed'
    },
    sectorIndex() {
      return this.$store.state.sectors.findIndex((sector) => sector.id === this.sector.id)
    },
    selected() {
      return this.sector[`${this.type}`]
    },
    materials() {
      return this.params?.materials
    },
    material() {
      return this.materials ? this.materials.find((material) => material.title === this.title) : []
    },
    thicknessUnitType() {
      return this.params ? this.params.thicknessUnitType : ''
    },
    densityUnitType() {
      return this.params ? this.params.densityUnitType : ''
    },
    title() {
      return this.sector[`${this.type}`] && this.sector[`${this.type}`][`${this.type}`]
        ? this.sector[`${this.type}`][`${this.type}`].title
        : ''
    },
    formattedMaterials() {
      return this.materials?.map((material) => {
        return {
          id: material.id,
          value: material.title
        }
      }) ?? []
    },
    densities() {
      const unit = this.densityUnitType

      return this.title && (this.isFacing || this.isBase) && this.material?.densities
        ? this.material.densities.map((item, index) => ({ id: index, value: parseInt(item, 10), unit }))
        : [ { id: 0, value: '', unit } ]
    },
    thicknesses() {
      const unit = this.thicknessUnitType

      return this.title && this.material?.thicknesses
        ? this.material?.thicknesses.map((item, index) => {
          return item?.size
            ? { id: index, value: item.size, unit, count: item.count }
            : { id: index, value: item, unit }
        })
        : [ { id: 0, value: '', unit } ]
    },
    sheets() {
      const unit = this.$t('message.units.pc')

      return this.title && this.material?.qtySheet
        ? this.material?.qtySheet.map((item, index) => ({ id: index, value: item, unit }))
        : [ { id: 0, value: '', unit } ]
    },
    isProAlgorithm() {
      return this.sector.algorithm === CalcTypes.pro
    },
    currentConstructionType() {
      return getSectorConstructionTypeId(this.getConstructionTypes, this.sector) === ConstructionTypes.floor
        ? ConstructionTypes.floor
        : ConstructionTypes.wall
    }
  },
  methods: {
    update(evt, property, subproperty) {
      const isParamValid = evt?.valid !== undefined ? evt.valid : null

      subproperty === this.type
        ? this.resetConstructionParamsValues(property, evt)
        : this.setConstructionParamsValues(property, subproperty, evt.newValue, isParamValid)
    },
    resetConstructionParamsValues(property, evt) {
      this.structure
        .filter((item) => item.viewCondition)
        .map(item => item.id).forEach((subproperty) => {
          this.setConstructionParamsValues(
            property,
            subproperty,
            subproperty === this.type ? evt.newValue : this.getDefaultValue(subproperty),
            true
          )
        })

      if (this.sector.construction || this.optionalSystems?.length) {
        this.resetConstructions()
      }
    },
    setConstructionParamsValues(property, subproperty, value, validitiy) {
      const { sectorIndex: index } = this
      this.$store.commit('UPDATE_SECTOR', { index, property, subproperty, value })
      if (validitiy !== null) {
        this.$store.commit('UPDATE_SECTOR', { index, property: 'isValid', subproperty: null, value: validitiy })

        if (!validitiy) {
          this.resetConstructions()
        }
      }
    },
    getLabel() {
      switch (this.type) {
        case 'facing':
          return this.$t('message.constructionParams.facingParams.facing')
        case 'isolation':
          return this.$t('message.constructionParams.isolationParams.isolation')
        case 'base':
          return this.$t('message.constructionParams.params.base')
        case 'screed':
          return this.$t('message.constructionParams.params.screed')
        case 'soundIsolation':
          return this.$t('message.constructionParams.soundIsolationParams.soundIsolation')
        default:
          return ''
      }
    },
    resetConstructions() {
      ['construction', 'optionalSystems', 'isCalculated']
        .forEach((property) => this.$store.commit('UPDATE_SECTOR', {
          index: this.sectorIndex, property, subproperty: null, value: null
        }))
    },
    getDefaultValue(param) {
      if (!param || !this.material || !this.material?.defaultValues) {
        return null
      }

      return this.material.defaultValues[`${param}`]
    }
  }
}
</script>

<style lang="sass">
.wizard:not(:has(aside))
  .structure
    &__wrapper
      +media((grid-template-columns: (0: repeat(2, 1fr), 1230: minmax(calc(100% / 3), 1fr) 1fr 1fr)))
.structure
  &__wrapper
    display: grid
    grid-gap: rem(16)
    margin: 0 0 rem(20)
    grid-template-rows: auto
    +media((grid-template-columns: (0: repeat(2, 1fr), 1230: minmax(0, rem(357)) rem(213) rem(213))))
    +media((grid-template-areas: (0: 'screed screed' 'thickness thickness', 1230: 'screed screed thickness' 'screed screed thickness')))
    &--base
      +media((grid-template-areas: (0: 'base base' 'density density' 'thickness thickness', 600: 'base base' 'density thickness', 1230: 'base density thickness' 'base density thickness')))
  &--pro
    .structure__wrapper
      grid-gap: rem(16)
      margin: 0 0 rem(20)
      grid-template-rows: auto
      +media((grid-template-columns: (0: repeat(2, 1fr), 1230: minmax(calc(100% / 3), 1fr) 1fr 1fr)))
      +media((grid-template-areas: (0: 'isolation isolation' 'thickness thickness', 800: 'isolation thickness' 'isolation thickness', 1230: 'isolation isolation thickness' 'isolation isolation thickness')))
      &--facing
        +media((grid-template-areas: (0: 'facing facing' 'density density' 'thickness thickness' 'sheets sheets', 800: 'facing density' 'thickness sheets', 1230: 'facing facing facing' 'density thickness sheets')))
        margin-bottom: rem(30)
      &--base
        +media((grid-template-columns: (0: repeat(2, 1fr), 1230: repeat(3, minmax(250px, 1fr)))))
        +media((grid-template-areas: (0: 'base base' 'density density' 'thickness thickness', 800: 'base base' 'density thickness', 1230: 'base density thickness' 'base density thickness')))
      &--screed
        +media((grid-template-areas: (0: 'screed screed' 'thickness thickness', 800: 'screed screed' 'thickness thickness', 1230: 'screed screed thickness' 'screed screed thickness')))
      &--sound
        +media((grid-template-areas: (0: 'soundIsolation soundIsolation' 'thickness thickness', 800: 'soundIsolation thickness' 'soundIsolation thickness', 1230: 'soundIsolation soundIsolation thickness' 'soundIsolation soundIsolation thickness')))
</style>
