<template>
  <section class="construction-params" :class="{ 'construction-params--with-filling': sector.filling }">
    <article v-if="!isProAlgorithm" class="construction-params__forms">
      <template v-if="baseParams">
        <Structure :params="baseParams" type="base" :sector="sector">
          <h5 class="construction-params__title">
            {{ $t('message.constructionParams.params.title') }}
          </h5>
        </Structure>
        <Structure :params="screedParams" type="screed" :sector="sector" v-if="screedParams"/>
      </template>
    </article>
    <article v-else class="construction-params__forms">
      <template v-for="structure of proStructures" >
        <Structure :params="structure.source" :type="structure.type" :sector="sector" :key="structure.id">
          <h5 v-if="structure.key" class="construction-params__title">
            {{ $t(`message.constructionParams.${structure.key}.title`) }}
          </h5>
        </Structure>
      </template>
    </article>
    <Index :sectorIndexes="sector.indexes" v-if="readyParams || !baseParams"/>
    <template>
      <ErrorNotification
        v-if="isGettingErrors"
        :notifications="errorNotifications"
        :constructionType="sector.constructionType.type" />
    <Constructions
      v-if="isThereOptionalSystem && !isGettingErrors && (readyParams || !baseParams)"
      :constructions="sector.optionalSystems"
      :selectedConstructions="sector.construction"
      @handle="setActiveConstruction($event)"
    />
    </template>
  </section>
</template>

<script>
import Index from '@/components/dump/IndexComponent'
import Structure from '@/components/smart/ConstructionStructure'
import { mapActions, mapGetters } from 'vuex'
import Constructions from '@/components/smart/Constructions'
import { getSuitableSystems } from '@/api'
import { CalcTypes, ConstructionTypes } from '@/utils/types'
import { getSectorConstructionTypeId } from '@/utils/utils'
import ErrorNotification from '@/components/dump/ErrorNotification.vue'

export default {
  name: 'ConstructionParams',
  props: ['sector'],
  components: { Constructions, Index, Structure, ErrorNotification },
  watch: {
    selectedOptionalSystem: function(newValue, oldValue) {
      if (newValue && oldValue && this.sector.isCalculated && newValue.id !== oldValue.id) this.updateSectorParams('isCalculated', false)
      if (newValue !== null && !this.isFloorPRO) this.getSuitableSystems(this.selectedOptionalSystem)
    },
    readyParams: function(newValue, oldValue) {
      if (!this.isProAlgorithm && oldValue && !newValue) {
        this.updateSectorParams('isCalculated', false)
      }
    },
    base: function () {
      this.reset()
    },
    screed: function () {
      this.reset()
    },
    facing: function () {
      this.reset()
    },
    isolation: function () {
      this.reset()
    },
    soundIsolation: function () {
      this.reset()
    }
  },
  mounted() {
    if (this.sector.structures && this.sector.structures.length === 0) {
      this.getOptionalSystem(this.sectorIndex, true)
    }
  },
  computed: {
    ...mapGetters({
      getConstructionTypes: 'getConstructionTypes'
    }),
    sectorIndex() {
      return this.$store.state.sectors.findIndex((sector) => sector.id === this.sector.id)
    },
    errorNotifications() {
      return this.sector.errors
    },
    selectedOptionalSystem() {
      return this.sector.construction
    },
    constructionStructure() {
      return this.sector.structures
    },
    baseParams() {
      return this.getStructure(this.$t('message.constructionParams.params.base'))
    },
    screedParams() {
      return this.getStructure(this.$t('message.constructionParams.params.screed'))
    },
    facingParams() {
      return this.getStructure(this.$t('message.constructionParams.facingParams.constructionGroup'))
    },
    isolationParams() {
      return this.getStructure(this.$t('message.constructionParams.isolationParams.constructionGroup'))
    },
    soundIsolationParams() {
      return this.getStructure(this.$t('message.constructionParams.soundIsolationParams.constructionGroup'))
    },
    base() {
      return this.sector.base
    },
    screed() {
      return this.sector.screed
    },
    facing() {
      return this.sector.facing
    },
    isolation() {
      return this.sector.isolation
    },
    soundIsolation() {
      return this.sector.soundIsolation
    },
    isProAlgorithm() {
      return this.sector.algorithm === CalcTypes.pro
    },
    isWallPRO() {
      return this.isProAlgorithm && this.currentConstructionType === ConstructionTypes.wall
    },
    isFloorPRO() {
      return this.isProAlgorithm && this.currentConstructionType === ConstructionTypes.floor
    },
    readyParams() {
      const method = this.isProAlgorithm
        ? () => this.getCalculationResult({ index: this.sectorIndex, type: this.isWallPRO ? null : ConstructionTypes.floor })
        : () => this.getOptionalSystem(this.sectorIndex)
      const isReady = this.isProAlgorithm
        ? !this.hasUnfilledParams(this.isWallPRO ? ['facing', 'isolation'] : ['base', 'soundIsolation', 'screed'])
        : !this.hasUnfilledParams(this.screedParams ? ['base', 'screed'] : ['base'])

      if (isReady && this.sector.isValid) {
        this.getConstructionScheme(this.sectorIndex)
        method()
      }

      return isReady
    },
    isThereOptionalSystem() {
      return this.sector.optionalSystems?.length
    },
    currentConstructionType() {
      return getSectorConstructionTypeId(this.getConstructionTypes, this.sector)
    },
    isGettingErrors() {
      return this.errorNotifications
        ? Object.values(this.errorNotifications).some((error) => error.needRender)
        : false
    },
    proStructures() {
      const proFloorSet = [
        { key: 'params', type: 'base', source: this.baseParams },
        { key: '', type: 'soundIsolation', source: this.soundIsolationParams },
        { key: '', type: 'screed', source: this.screedParams }
      ]
      const proWallSet = [
        { key: 'facingParams', type: 'facing', source: this.facingParams },
        { key: 'isolationParams', type: 'isolation', source: this.isolationParams }
      ]

      return this.currentConstructionType === ConstructionTypes.floor ? proFloorSet : proWallSet
    }
  },
  methods: {
    ...mapActions({
      getConstructionScheme: 'getDrawing',
      getOptionalSystem: 'getOptionalSystem',
      setCalculatedIndexes: 'setCalculatedIndexes',
      getCalculationResult: 'getCalculationResult',
      setTestingStatus: 'setTestingStatus'
    }),
    setActiveConstruction(evt) {
      this.updateSectorParams('construction', evt.value)
    },
    getSuitableSystems(system) {
      this.setCalculatedIndexes({ index: this.sectorIndex, payload: { rw: system?.rw, lnw: system?.lnw } })
      this.setTestingStatus({ index: this.sectorIndex, payload: system?.isTested })

      const requestData = {
        optionSystemId: this.sector.construction?.id,
        rw: this.sector.indexes.calculated?.rw,
        lnw: this.sector.indexes.calculated?.lnw,
        rwRequired: this.sector.indexes.required?.rw,
        lnwRequired: this.sector.indexes.required?.lnw
      }

      getSuitableSystems(requestData).then((response) => {
        const errors = {
          rw: {
            text: response.data.rw,
            needRender: response.data.rw !== null ? requestData.rw <= requestData.rwRequired : false
          },
          lnw: {
            text: response.data.lnw,
            needRender: response.data.lnw !== null ? requestData.lnw >= requestData.lnwRequired : false
          }
        }

        this.updateSectorParams('errors', errors)

        this.isGettingErrors
          ? this.updateSectorParams('optionalSystems', null)
          : this.updateSectorParams('isCalculated', true)
      })
    },
    updateSectorParams(property, value) {
      this.$store.commit('UPDATE_SECTOR', { index: this.sectorIndex, property, value })
    },
    resetErrors() {
      this.updateSectorParams('errors', null)
      this.updateSectorParams('image', null)
    },
    resetCalculationStatus() {
      if (this.sector.isCalculated) {
        this.updateSectorParams('isCalculated', false)
      }
    },
    reset() {
      this.resetCalculationStatus()
      this.resetErrors()
    },
    hasUnfilledParams(params) {
      return params.some((param) => Object.values(this[param]).filter((value) => !value).length !== 0)
    },
    getStructure(name) {
      return this.constructionStructure?.find((structure) => structure.constructionGroup === name)
    }
  }
}
</script>

<style scoped lang="sass">
.construction-params
  display: flex
  flex-wrap: wrap
  width: 100%
  &--with-filling
    .construction-params__forms
      order: 1
    .filling
      order: 2
      +media((margin-bottom: (0: rem(10), 768: 0)))
    .indexes
      order: 3
    .constructions
      order: 4
  &__title
    margin: 0 0 rem(20)
    font-weight: 600
    line-height: 1.3
  &__forms
    width: 100%
  &__forms + .indexes:has(.indexes__list:not(:empty))
    +media((margin-top: (0: rem(10), 768: rem(30))))
  .filling + .constructions
    margin-top: rem(30)
</style>
